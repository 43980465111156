import React, {Component}        from 'react'
import cn                        from 'classnames'
import PropTypes                 from 'prop-types'
import VerticalCenter            from './vertical-center'
import PhotoInfo                 from './photo-info'
import DocumentsModal            from './documents-modal'
import _                         from 'lodash'
import {LeftButton, RightButton} from './browse-buttons'
import BackNextSwitch            from './back-next-switch'
import GlobalMenu                from './global-menu-icon'
import {inverseScale}            from '../scale';
import sizeToFit                 from '../util/size-to-fit'
import { withRouter } from "react-router-dom";

let albumArtVerticalMargin = 25;
let albumArtHorizontalMargin = 25; //room for buttons
//candidate height for album art
function measureHeight() {
  return inverseScale() * window.innerHeight - albumArtVerticalMargin * 2;
}
//available width for album art
function measureWidth() {
  return inverseScale() * window.innerWidth - albumArtHorizontalMargin * 2;
}

function sizeAlbumArt() {
  return { width: measureWidth(), height: measureHeight() };
}

class PhotoModal extends Component {
  constructor(props, b) {
    super(props, b);

    let { photo, collection } = props;

    let { search } = props.location;
    const searchParams = new URLSearchParams(search);
    let zoom = !!(
      (searchParams.get("zoom") && parseInt(searchParams.get("zoom"), 10)) ||
      0
    );

    this.state = { photo, collection, zoom };

    this.onClickBG = this.onClickBG.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onZoom = this.onZoom.bind(this);
    this.onClickZoom = this.onClickZoom.bind(this);
    this.measure = this.measure.bind(this);
    this.clickImage = this.clickImage.bind(this);
  }
  componentWillMount() {
    window.addEventListener("resize", this.measure);
    this.measure();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.measure);
  }
  measure() {
    this.setState({ contentArea: sizeAlbumArt() });
  }
  UNSAFE_componentWillReceiveProps(newprops) {
    let { photo, collection, location } = newprops;
    const searchParams = new URLSearchParams(location.search);
    let zoom = !!(
      (searchParams.has("zoom") && parseInt(searchParams.get("zoom"), 10)) ||
      0
    );
    this.setState({ photo, collection, zoom });
  }
  hasZoom() {
    let { photo } = this.state;
    let hasZoom = photo && !!photo.zoom;

    let zoomOverride = typeof this.props.alwaysShowZoom !== "undefined";

    if (zoomOverride) hasZoom = this.props.alwaysShowZoom;

    return hasZoom;
  }
  onClickBG(e) {
    if (e.target === this.refs.bg) {
      if (this.hasZoom()) this.onZoom();
    }
  }
  onClose() {
    this.props.onDismiss();
  }
  move(dir) {
    let { photo, collection } = this.state;
    let { id } = photo;
    let idx = _.findIndex(collection, { id }) + dir;

    photo = collection[idx];
    this.setState({ photo });
    this.props.onUpdate && this.props.onUpdate(photo, idx);
  }
  onClickZoom() {
    this.onZoom();
  }
  onZoom(params = {}) {
    const { location, history } = this.props;
    const currentParams = new URLSearchParams(location.search);
    console.log(history);
    // Merge in new params
    Object.entries(params).forEach(([key, value]) => {
      currentParams.set(key, value);
    });

    // Add zoom=1
    currentParams.set("zoom", "1");

    // Push updated URL
    const newUrl = `${location.pathname}?${currentParams.toString()}`;
    history.push(newUrl);
  }
  renderZoomed() {
    let { x, y } = this.props.location.search;
    return (
      <DocumentsModal
      {...this.props}
        photo={this.state.photo}
        darkBackground={this.props.darkBackground}
        initialX={x}
        initialY={y}
        showZoom={true}
      />
    );
  }
  clickImage(e) {
    if (!this.hasZoom()) return;

    let x = e.clientX,
      y = e.clientY;
    let r = this.refs.image.getBoundingClientRect();
    let w = r.right - r.left,
      h = r.bottom - r.top;
    x -= r.left;
    y -= r.top;

    x = x / w;
    y = y / h;
    this.onZoom({ x, y });
  }
  renderAlbumArt(photo, full) {
    let { width, height } = this.state.contentArea;
    let { fullWidth, fullHeight } = photo;
    let imageSize = sizeToFit(fullWidth, fullHeight, width, height);

    let containerStyle = {
      width: width + "px",
      height: height + "px",
    };

    let imageStyle = {
      height: imageSize.height + "px",
    };

    return (
      <VerticalCenter className="photo-container">
        <div className="album-art" style={containerStyle}>
          <div className="image-wrapper" onClick={this.clickImage}>
            <img
              ref="image"
              src={full}
              style={imageStyle}
              key={full}
              loading="lazy"
            />
          </div>
          <div className="spacer" />
        </div>
      </VerticalCenter>
    );
  }
  renderPhoto(photo, full) {
    let photoClass = cn("photo", "is-normal");

    return (
      <VerticalCenter className="photo-container">
        <div className={photoClass}>
          <PhotoInfo photo={photo} />
          <div className="image-wrapper" onClick={this.clickImage}>
            <img ref="image" src={full} key={full} loading="lazy" />
          </div>
          <div className="spacer" />
        </div>
      </VerticalCenter>
    );
  }
  renderNormal() {
    let { photo, collection } = this.state;

    let moreLeft, moreRight, full;

    let isAlbumArt = photo.type === "albumArt";
    let useDarkBG = !!this.props.darkBackground;

    if (photo) {
      let { id } = photo;

      if (collection) {
        let idx = _.findIndex(collection, { id });
        moreLeft = idx > 0;
        moreRight = idx < collection.length - 1;
      } else {
        moreLeft = false;
        moreRight = false;
      }

      full = photo.full;
    } else {
      moreLeft = false;
      moreRight = false;
      full = "";
    }

    let normal = !isAlbumArt && !useDarkBG;
    let dark = !isAlbumArt && useDarkBG;

    let classes = { "album-art": isAlbumArt, normal, dark };
    let cx = cn("photo-modal", classes);

    return (
      <div ref="bg" id="photo-modal" className={cx} onClick={this.onClickBG}>
        {photo
          ? isAlbumArt
            ? this.renderAlbumArt(photo, full)
            : this.renderPhoto(photo, full)
          : null}
        <div className="close-button" onClick={this.onClose} />
        {/* <div className="controls">
               <BackNextSwitch />
               <GlobalMenu />
               </div> */}
        <LeftButton show={moreLeft} onClick={() => this.move(-1)} />
        <RightButton show={moreRight} onClick={() => this.move(+1)} />
        {this.hasZoom() ? (
          <div className="zoom-in" onClick={this.onClickZoom} />
        ) : null}
      </div>
    );
  }
  render() {
    return this.state.zoom ? this.renderZoomed() : this.renderNormal();
  }
}

PhotoModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withRouter(PhotoModal);