import React, {Component} from 'react'
import PropTypes          from 'prop-types'
import VerticalCenter from './vertical-center'
import superagent from 'superagent';

function fmt_date({month, day, year}){
    let yr = year.toString().slice(2, 4)
    return `${month}/${day}/${yr}`
}

class Item extends Component {
    render(){
        let {title, notes, dateText, date, _id} = this.props.video

        let backgroundImage = `url('${this.props.thumbnail}')`;

        let formattedDateText = dateText || (date && fmt_date(date)) || ''

        return (
            <div className="row" onClick={()=>{this.props.onClick(_id)}}>
              <div className="icon">
                <div className="background" style={{backgroundImage}}></div>
                <div className="play"></div>
              </div>
              <div className="title">{title}</div>
              <div className="date">{formattedDateText}</div>
              <div className="notes">{notes}</div>
            </div>)
    }
}

export default class VideosModal extends Component {
    constructor(props){
        super(props)

        this.onBGClick = this.onBGClick.bind(this)
        this.onVideoClick = this.onVideoClick.bind(this)
        this.unbind = this.unbind.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }
    componentWillMount(){
        document.addEventListener('mousedown', this.hideModal)
    }
    hideModal(e){
        if (e.target.closest('#videos-modal')) return

        e.preventDefault()
        e.stopPropagation()

        this.unbind()
        this.props.onClose(e)
    }
    componentWillUnmount(){
        this.unbind()
    }
    unbind(){
        document.removeEventListener('mousedown', this.hideModal)
    }
    getItems(){
        let {track, thumbnails} = this.props
        let videos  = track.videos || []
        return videos.map((v, idx)=> <Item key={idx} video={v} thumbnail={thumbnails[idx]}
                                           onClick={this.onVideoClick}/>)
    }
    onBGClick(e){
        if (e.target !== this.refs.background) return

        this.props.onClose(e)
    }
    onVideoClick(id){
        let {history} = this.props
        //todo check if need to be modal
        history.push(`/video?id=${id}`)
    }
    render(){
        return (
            <div id="videos-modal" ref="background" onClick={this.onBGClick}>
              <VerticalCenter className="container">
                <div className="content">
                  <div className="background"></div>
                  <div className="fold"></div>
                  <div className="close" onClick={this.props.onClose}></div>
                  <div className="items">
                    {this.getItems()}
                  </div>
                </div>
              </VerticalCenter>
            </div>
        )
    }
}

VideosModal.contextTypes = {
    router: PropTypes.object.isRequired
}
