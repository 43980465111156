import React, {Component}   from 'react'
import cn                   from 'classnames'
import _                    from 'lodash'
import {centralColumnWidth} from '../scale'
import interpose            from '../util/interpose'
import {scale}              from '../scale'
import {isIPhone, isIPad}   from '../platform'

function end(event){
    if (event.stopPropagation()) event.stopPropagation()
    if (event.preventDefault()) event.preventDefault()

    return false;
}

export default class PhotoGallery extends Component {
    constructor(props, b){
        super(props, b)

        /* TODO: we only really need the 'min' and 'max' positions now, 
         * can replace all the 'page' code */
        let pages = this._calcPageIndexes(props.photos)
        let count = pages.length
        let left  = this.getLeftOffset(0, count, props.photos)
        let max   = this.getLeftOffset(pages[0], count, props.photos)
        let min   = this.getLeftOffset(pages[pages.length-1], count, props.photos)

        this.state = {page: 0, pages, count, left, max, min}

        this.onClick    = this.onClick.bind(this)
        this.mouseDown  = this.mouseDown.bind(this)
        this.mouseMove  = this.mouseMove.bind(this)
        this.mouseUp    = this.mouseUp.bind(this)
        this.touchStart = this.touchStart.bind(this)
        this.touchMove  = this.touchMove.bind(this)
        this.touchEnd   = this.touchEnd.bind(this)

    }
    UNSAFE_componentWillReceiveProps(newprops){
        let pages = this._calcPageIndexes(newprops.photos)
        let count = pages.length

        this.setState({pages, count})
    }
    _mouseMap(){
        return {mousemove: this.mouseMove, mouseup:this.mouseUp}
    }
    _touchMap(){
        return {touchmove:this.touchMove, touchend:this.touchEnd}
    }
    _addHandlers(map){
        _.each(map, (value, key)=>{
            document.addEventListener(key, value, false)
        })
    }
    _removeHandlers(map){
        _.each(map, (value, key)=>{
            document.removeEventListener(key, value, false)
        })
    }
    mouseDown(e){
        if (this.state.count === 1) return

        this._addHandlers(this._mouseMap());
        return this._down(e, e.clientX)
    }
    mouseMove(e){
        return this._move(e, e.clientX)
    }
    mouseUp(e){
        this._removeHandlers(this._mouseMap())
        return this._up(e)
    }
    touchStart(e){
        if (e.touches.length > 1) return
        if (this.state.count === 1) return

        this._addHandlers(this._touchMap());
        return this._down(e, e.touches[0].clientX)
    }
    touchMove(e){
        if (e.touches.length > 1) return
        return this._move(e, e.touches[0].clientX)
    }
    touchEnd(e){
        if (e.touches.length > 1) return
        this._removeHandlers(this._touchMap())
        return this._up(e)
    }
    _down(e, clientX){
        this._offset = clientX
        this._moved  = false
        this._left   = this.state.left
        return end(e)
    }
    _move(e, clientX){
        let diff = this._offset - clientX

        this._offset = clientX
        this._moved  = true

        diff *= 1/scale()

        this._left = this.getLeft(this._left - diff)
        this.refs.row.style.left = this._left + 'px'

        return end(e)
    }
    _up(e){
        if (this._moved) this.setState({left:this._left})
        return end(e)
    }
    onClick(e){
        end(e)
        if (this._moved) return

        let el = e.target
        let id = el.getAttribute('data-id')

        this.selectPhoto(id);
    }
    selectPhoto(id){
        let photo = _.find(this.props.photos, {id})
        this.props.onSelect(photo)
    }
    getPhotos(){
        return this.props.photos.map(({id, thumb, thumbWidth, thumbHeight})=> {
            let style = {width:  thumbWidth + 'px',
                         height: thumbHeight + 'px',
                         backgroundImage:`url(${thumb})`
            }
            return (
                <div style={style} className="photo" key={`photo-${id}`} data-id={id} onClick={this.onClick} />
            )
        })
    }
    singlePageLeftOffset(){
        let {photos}    = this.props
        let folderWidth = 1104
        let spacerWidth = 30 * (photos.length - 1)
        let plus        = (sum, {thumbWidth}) => sum + thumbWidth;
        let itemsWidth  = photos.reduce(plus, 0) + spacerWidth
        let offs        = (folderWidth - itemsWidth) / 2
        return offs
    }
    getLeftOffset(index, count=this.state.count, photos=this.props.photos){
        if (count === 1) return this.singlePageLeftOffset();

        let curr = 0, offs = 0
        while (curr < index) {
            offs += photos[curr].thumbWidth
            curr++
        }
        offs += 30 * index //spacers

        offs = -offs

        offs -= photos[index].thumbWidth * 0.6

        return offs
    }
    _calcPageIndexes(photos) {
        if (!photos.length) return []

        let maxIndex = this._calcMaxIndex(photos)
        let indexes  = [0]
        let next     = this._calcNextIndex(photos, 0, maxIndex)

        while (next !== indexes[indexes.length - 1]) {
            indexes.push(next)
            next = this._calcNextIndex(photos, next, maxIndex)
        }

        return indexes
    }
    _calcMaxIndex(photos){
        let max = photos.length - 1
        let idx = max
        let available = centralColumnWidth()
        while (idx > -1 && available > 0) {
            if (idx === max) {
                available -= (photos[idx].thumbWidth * 0.4)
            } else {
                available -= (photos[idx].thumbWidth + 30)
            }
            idx--
        }
        return idx + 1
    }
    _calcNextIndex(photos, index, maxIndex){

        let available = centralColumnWidth() - 30
        let next      = index

        while(next < photos.length && available > 0) {
            let width = photos[next].thumbWidth
            if (next === index) {
                available -= ((width * 0.4) + 30)
            } else {
                available -= (width + 30)
            }
            next++
        }

        return Math.min(next, maxIndex)
    }
    getLeft(value){
        let {max, min} = this.state
        return Math.max(min, Math.min(max, value))
    }
    render(){
        let photos = this.getPhotos()

        let cx = cn({
            'photo-row':true,
            'no-shadow': isIPhone || isIPad,
            ['row-length-'+photos.length]: true
        })

        let html = interpose(photos, (idx)=><div className="spacer" key={`spacer-${idx}`} />)

        let allPhotosWidth = this.props.photos.reduce(((sum,{thumbWidth})=>sum+thumbWidth),0)
        allPhotosWidth += 30 * this.props.photos.length - 1

        let {left} = this.state

        let style={width:allPhotosWidth, left: `${left}px`}

        let row = <div className={cx} ref="row" style={style}
                       onMouseDown={this.mouseDown}
                       onTouchStart={this.touchStart}>{html}</div>;

        return (<div id="photo-gallery">{row}</div>)
    }
}
